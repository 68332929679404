<template>
    <div class="wrapper">
        <iTable
            :table-data="dataList"
            :columns="columns"
            :pagination="pagination"
            tabletype="1"
            ref="iTable"
            :other-height="230"
            :border="false"
            @handleTableChange="handleTableChange"
            @readAllClick="readAll"
            @msgRead="msgRead"
            @starFn="starFn"
        ></iTable>
    </div>
</template>

<script>
import iTable from './components/iTable';
import { eventBus } from '@/common/eventBus.ts';
export default {
    components: {
        iTable,
    },
    props: {
        msgType: {
            type: [Number],
        },
    },
    data() {
        return {
            currentIndex: 1,
            pagination: { size: 10 },
            dataList: [],
            columns: [
                {
                    prop: 'is_read',
                    label: '',
                    width: 100,
                    format: row => {
                        let str = '';
                        if (row.is_read) {
                            str = '<span class="iconfont icondot readed"></span>';
                        } else {
                            str = '<span class="iconfont icondot unread"></span>';
                        }
                        return str;
                    },
                    isHeader: true,
                },
                {
                    prop: 'group',
                    label: '消息类型',
                },
                {
                    prop: 'title',
                    label: '消息标题',
                    format: row => {
                        return '<a href="javascript:;">' + row.title + '</a>';
                    },
                    method: this.openDetail,
                },
                {
                    prop: 'createtime',
                    label: '创建时间',
                },
                // {
                //     prop: 'is_star',
                //     label: '是否标记',
                //     format: row => {
                //         let str = '';
                //         if (row.is_star) {
                //             str = '<span class="iconfont iconicon-starfilled"></span>';
                //         } else {
                //             str = '<span class="iconfont iconicon-star"></span>';
                //         }
                //         return str;
                //     },
                //     method: this.starFn,
                // },
            ],
        };
    },
    watch: {},
    computed: {},
    methods: {
        handleTableChange() {
            if (Math.ceil(this.pagination.total / this.pagination.size) !== this.currentIndex) {
                this.currentIndex++;
                this.getMsgList();
            }
        },
        getMsgList(i) {
            const index = i || i === 0 ? Math.floor(i / 10) + 1 : this.currentIndex;
            const qs = require('qs');
            const param = {
                type: 99,
                pageindex: index,
                pagesize: 10,
            };
            this.$axios
                .get('/interfaceApi/message/MessageInfo/get_page?' + qs.stringify(param))
                .then(res => {
                    if (i || i === 0) {
                        this.dataList.forEach(item => {
                            res.rows.forEach(dat => {
                                if (item.Id === dat.Id) {
                                    item.IsStar = dat.IsStar;
                                    item.IsRead = dat.IsRead;
                                }
                            });
                        });
                    } else {
                        this.dataList.push(...res.rows);
                    }
                    this.pagination.total = res.total;
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },
        // 标记操作
        starFn(row, i) {
            if (row.IsStar) {
                this.removeStar(row.Id, i);
            } else {
                this.addStar(row.Id, i);
            }

        },
        // 添加标记
        addStar(id, i) {
            this.$axios
                .put('/interfaceApi/message/MessageInfo/add_star/' + id)
                .then(res => {
                    this.$message.success(res);
                    this.getMsgList(i);
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },
        // 添加标记
        removeStar(id, i) {
            this.$axios
                .put('/interfaceApi/message/MessageInfo/remove_star/' + id)
                .then(res => {
                    this.$message.success(res);
                    this.getMsgList(i);
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },
        // 查看信息详情
        openDetail(row) {
            this.msgRead(row.id);
        },
        // 已读消息
        msgRead(id, i) {
            this.$axios
                .put('/interfaceApi/message/MessageInfo/message_read/' + id)
                .then(() => {
                    // this.$message.success(res);
                    this.getMsgList(i);
                    eventBus.$emit('updateSysUnread');
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },
        readAll() {
            this.$axios
                .get('/interfaceApi/message/MessageInfo/batch_read?type=99')
                .then(() => {
                    this.$message.success('操作成功');
                    this.getMsgList();
                    eventBus.$emit('updateSysUnread');
                }).catch(error => {
                    this.$message.error(error.message);
                });
        },
    },
    created() {
        this.getMsgList();
    },
    mounted() {
        // 添加事件总线eventBus
        eventBus.$on('updateNoticefyMsg', () => {
            this.getMsgList();
        });
    },
    beforeDestroy() {
        // 清除事件总线eventBus， 不手动清除，它是一直会存在的，就会反复进入
        eventBus.$off('updateNoticefyMsg');
    },
};
</script>
<style scoped>
.wrapper{
    padding: 10px;
    height: 100%;
}
</style>